import React, { useEffect, useState, useRef } from 'react';
import { Field, Input, Button } from "@fluentui/react-components";
import { Stack, StackItem, DatePicker, DayOfWeek, defaultDatePickerStrings } from "@fluentui/react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";

function ChildInfoContainer(props) {
  // eslint-disable-next-line
  const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Sunday);
  const [surname, setSurname] = useState('');
  const [firstname, setFirstname] = useState('');

  // eslint-disable-next-line
  const [birthday, setBirthday] = useState(undefined);
  const [birthdate, setBirthdate] = useState(undefined);
  const [filepath, setFilepath] = useState('');

  const [surnameError, setSurnameError] = useState(true);
  const [firstnameError, setFirstnameError] = useState(true);
  const [birthdayError, setBirthdayError] = useState(true);
  const [filepathError, setFilepathError] = useState(true);
  const [preview, setPreview] = useState()
  const [showPreview, setShowPreview] = useState(false);
  const [lightBoxData, setLightBoxData] = useState();

  useEffect(() => {
    setSurname(props.item.surname);
    setFirstname(props.item.firstname);
    setBirthday(props.item.birthday);
  }, [props])

  useEffect(() => {
    const hasError = surnameError || firstnameError || birthdayError || filepathError;

    if (props.item.error !== hasError) {
      props.item.error = hasError;
      props.validateAction();
    }
    // eslint-disable-next-line
  }, [props.item.error, props.validateAction, surnameError, firstnameError, birthdayError, filepathError])

  var inputFile = useRef < HTMLInputElement | null > (null);

  const onFileChange = async (e) => {
    if(inputFile.files.length === 0)
      return;
    
    const file = inputFile.files[0];
    const url = URL.createObjectURL(file);
    props.item.file = file;
    props.item.fileUrl = url;

    if(inputFile.files[0].type !== 'application/pdf') {
      const objectUrl = URL.createObjectURL(file)
      setPreview(objectUrl)
      setLightBoxData({ src: objectUrl, key: objectUrl, width: inputFile.files[0].width, height: inputFile.files[0].height });
    } else {
      setPreview(undefined);
      setLightBoxData(undefined);
    }
    

    setFilepath(file.name);
    setFilepathError(file.name === undefined || url === '');
  };

  const onUpload = (e) => {
    inputFile.click();
  }

  const onChange = (e) => {
    if (e !== undefined) {
      switch (e.target.id) {
        case 'surname':
          props.item.surname = e.target.value;
          setSurname(e.target.value);
          setSurnameError(e.target.value === undefined || e.target.value.length === 0);
          break;
        case 'firstname':
          props.item.firstname = e.target.value;
          setFirstname(e.target.value);
          setFirstnameError(e.target.value === undefined || e.target.value.length === 0);
          break;
        case 'birthday':
          props.item.birthday = e.target.value;
          setBirthday(e.target.value);
          setBirthdayError(e.target.value === undefined || e.target.value.length === 0 || new Date(e.target.value) > new Date());
          break;
        default:
          break;
      }
    }
  }

  const formatDate = (date) => {
    if (!date) return '';
    if ((typeof date) === 'string')
      return date;

    setBirthdate(date);

    var newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    const value = newDate.toISOString().split('T')[0];
    props.item.birthday = value;

    setBirthday(value);
    setBirthdayError(value === undefined || value === 0 || date > new Date());

    const month = date.getMonth() + 1; // + 1 because 0 indicates the first Month of the Year.
    const day = date.getDate();
    const year = date.getFullYear();

    return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year.toString().padStart(2, '0')}`;
  }

  const onParseDateFromString = React.useCallback((newValue) => {
    const previousValue = birthdate || new Date();
    const newValueParts = (newValue || '').trim().split('.');
    const day = newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
    const month = newValueParts.length > 1 ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1 : previousValue.getMonth();
    let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
    if (year < 100) {
      year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
    }
    return new Date(year, month, day);
  },
    [birthdate],
  );

  return <Row style={{ backgroundColor: props.item.idx % 2 === 0 ? '#dbdfe1' : '#ff', paddingTop: 10, paddingBottom: 10 }}>
    <Row>
      <Col xs={12} md={4} lg={3}>
        <Field label={`${props.item.idx}. Kind Vorname`} validationState={firstnameError ? 'error' : 'success'} validationMessage={firstnameError ? 'Bitte geben Sie Ihren Vornamen ein' : ''}>
          <Input id='firstname' type='text' value={firstname} onChange={(e) => onChange(e)} />
        </Field>
      </Col>
      <Col xs={12} md={4} lg={3}>
        <Field label="Nachname" validationState={surnameError ? 'error' : 'success'} validationMessage={surnameError ? 'Bitte geben Sie Ihren Nachnamen ein' : ''}>
          <Input id='surname' type='text' value={surname} onChange={(e) => onChange(e)} />
        </Field>
      </Col>
      <Col xs={12} md={4} lg={3}>
        <Field label="Geburtsdatum" validationState={birthdayError ? 'error' : 'success'} validationMessage={birthdayError ? 'Bitte geben Sie das Geburtsdatum an' : ''}>
          {/* <Input id='birthday' type='date' value={birthday} onChange={(e) => onChange(e)} /> */}
          <DatePicker
            firstDayOfWeek={firstDayOfWeek}
            placeholder="Datum auswählen"
            ariaLabel="Datum auswählen"

            value={birthdate}
            // onSelectDate={(e) => onSelectDate(e)}
            formatDate={formatDate}
            parseDateFromString={onParseDateFromString}

            // DatePicker uses English strings by default. For localized apps, you must override this prop.
            strings={defaultDatePickerStrings}
          />
        </Field>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={12}>
        <Stack>
        <Stack horizontal>
          <StackItem style={{ marginRight: 20 }}><Button style={{ marginTop: 25 }} onClick={() => props.deleteAction(props.item.idx)}>Kind löschen</Button></StackItem>
          <StackItem>
            <Button style={{ marginTop: 25 }} onClick={() => onUpload()}>Geburtsurkunde hochladen
              <input type='file' id='file' accept='.pdf,.docx,.jpg,.jpeg,.png' ref={(ref) => inputFile = ref} style={{ display: 'none' }} onChange={onFileChange} />
            </Button>
          </StackItem>
          {filepathError && <StackItem style={{ paddingTop: 30, paddingLeft: 15, color: '#bc2f32' }}>
            Sie müssen für dieses Kind eine Geburtsurkunde hochladen.
          </StackItem>}

          {!filepathError && <StackItem style={{ paddingTop: 30, paddingLeft: 15 }}>
            {filepath}
          </StackItem>}
        </Stack>
        { preview && <StackItem style={{marginTop:15, cursor: 'pointer'}} onClick={() => setShowPreview(true)}><img src={preview} alt='Geburtsurkunde' style={{maxWidth:200,maxHeight:200}} /></StackItem>}
        </Stack>
      </Col>
    </Row>
    { preview && <Lightbox
      slides={[lightBoxData]}
      open={showPreview}
      index={0}
      close={() => setShowPreview(false)}
      plugins={[Fullscreen, Zoom]}
    /> }
  </Row>;
}

export default ChildInfoContainer;
