import React, { useState, useEffect, useMemo } from 'react';
import { Field, Input, Button } from "@fluentui/react-components";
import { PrimaryButton, Spinner, SpinnerSize, Stack, StackItem } from '@fluentui/react';
import Container from 'react-bootstrap/Container';
import ChildInfoContainer from './ChildInfoContainer';
import SuccessAnimation from 'actually-accessible-react-success-animation';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import Banner from './Banner.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

initializeIcons();

function App() {
  const [loading, setLoading] = useState(false);
  const [children, setChildren] = useState([]);
  const [surname, setSurname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [employeeId, setEmployeeId] = useState('');

  const [surnameError, setSurnameError] = useState(true);
  const [firstnameError, setFirstnameError] = useState(true);
  const [employeeIdError, setEmployeeIdError] = useState(true);

  const [surnameReadOnly, setSurnameReadOnly] = useState(false);
  const [firstnameReadOnly, setFirstnameReadOnly] = useState(false);
  const [employeeIdReadOnly, setEmployeeIdReadOnly] = useState(false);

  const [dataSent, setDataSent] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search)
  const defaultFirstname = queryParameters.get("vorname")
  const defaultSurname = queryParameters.get("nachname")
  const defaultEmployeeId = queryParameters.get("personalnr")
  

  
  useEffect(() => {
    

    if(surname === undefined || surname === '') {
      setSurname(defaultSurname);
      setSurnameError(defaultSurname === undefined || defaultSurname === null || defaultSurname === '')
      setSurnameReadOnly((defaultSurname?.length ?? 0) > 0);
    }
      

    if(firstname === undefined || firstname === '') {
      setFirstname(defaultFirstname);
      setFirstnameError(defaultFirstname === undefined || defaultFirstname === null || defaultFirstname === '')
      setFirstnameReadOnly((defaultFirstname?.length ?? 0) > 0);
    }
      

    if(employeeId === undefined || employeeId === '') {
      setEmployeeId(defaultEmployeeId);
      setEmployeeIdError(defaultEmployeeId === undefined || defaultEmployeeId === null || defaultEmployeeId === '')
      setEmployeeIdReadOnly((defaultEmployeeId?.length ?? 0) > 0);
    }
      
  // eslint-disable-next-line
  }, [defaultFirstname, defaultSurname, defaultEmployeeId])
  
  const onChange = (e) => {
    if(e !== undefined) {
      switch(e.target.id) {
        case 'surname':
          setSurname(e.target.value);
          setSurnameError(e.target.value === undefined || e.target.value.length === 0);
          break;
        case 'firstname':
          setFirstname(e.target.value);
          setFirstnameError(e.target.value === undefined || e.target.value.length === 0);
          break;
        case 'employeeId':
            setEmployeeId(e.target.value);
            setEmployeeIdError(e.target.value === undefined || e.target.value.length === 0);
            break;
        default:
            break;
      }
    }
  }

  const hasErrors = useMemo(() => {
    var result = firstnameError || surnameError || employeeIdError || children.length === 0;

    children.forEach(o => {
      result = result || o.error;
    });
    return result;
  }, [children, firstnameError, surnameError, employeeIdError])

  const addItem = () => {
    const item = { idx: children.length + 1, surname: '', firstname: '', birthday: undefined, error: true };
    setChildren([...children, item]);
  }

  const deleteItem = (itemId) => {
    const idx = children.findIndex(o => o.idx === itemId);

    if(idx > -1) {
      children.splice(idx, 1);

      var i = 1;
      children.forEach(o => {
        o.idx = i++;
      });
      setChildren([...children]);
    }
      
  }

  const updateValidation = () => {
    setChildren([...children]);
  }

  const sendData = async () => {
    setLoading(true);

    for(var i = 0; i < children.length; i++) {
      var formData = new FormData();
      var child = children[i];

      formData.append('EmployeeFirstname', firstname);
      formData.append('EmployeeSurname', surname);
      formData.append('EmployeeId', employeeId);
      formData.append('ChildNo', i+1);
      formData.append('ChildSurname', child.surname);
      formData.append('ChildFirstname', child.firstname);
      formData.append('ChildBirthday', child.birthday);
      formData.append('file', child.file);

      const response = await fetch('/api/UpdateEmployeeResponse?code=5YfiEGXU6Yt9fc39iRemlVmEZMmiMXX3Pm0hPVaz_7X9AzFuVOw9UA==', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        // headers: {
        //   "Content-Type": "multipart/form-data",
        //   // 'Content-Type': 'application/x-www-form-urlencoded',
        // },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer",
        body: formData
      });
  
      if(response.status === 200) {
        setDataSent(true);
      }
    }

    setLoading(false);
  }

  return <div>
    <div style={{marginBottom:35}}>
      {/* <img src='https://rgfstaffing.de/uploads/assets/theme/rgf-staffing-logo.jpg' alt='RGF Staffing Logo' style={{marginBottom:15}}/> */}
      <img src={Banner} alt='RGF Staffing Logo' style={{marginBottom:35, maxWidth:'100%'}} />
      <h1>Meldung zur Pflegeversicherung</h1>
        <p style={{marginTop:20}}>Sehr geehrte Mitarbeitende,</p>
        <p>zum 1. Juli 2023 werden Eltern mit Kindern in der Pflegeversicherung entlastet. Diese Änderung ist im Pflegeunterstützungs- und -entlastungsgesetz (PUEG) verankert.</p>
        <p>Bis vor Kurzem hatten Sie hier noch die Möglichkeit online Ihre Daten zu erfassen. Da der Großteil der Erfassung abgeschlossen ist, haben wir das Formular abgeschaltet.</p>
        <p>Falls Sie dennoch eine Meldung vornehmen wollen, melden Sie sich bitte bei Ihrer Niederlassung.</p>
    </div>
    {/* { !dataSent && <div>
      <h2>Angaben zu Ihrer Person:</h2>
      <Container fluid style={{marginLeft:0, marginBottom:35, marginTop:20}}>
        <Row style={{padding:0, marginBottom:20, marginTop:10}}>
          Bitte geben Sie in den nachfolgenden Feldern Ihre persönlichen Daten ein. Sind die Felder vorbefüllt, können diese nicht geändert werden! 
Sind die Felder leer, tragen Sie bitte die Daten aus Ihrem Anschreiben/Brief ein, den Sie erhalten haben.
        </Row>
        <Row>
          <Col xs={12} md={12} lg={3}>
            <Field label="Personalnummer" validationState={employeeIdError ? 'error' : 'success'} validationMessage={employeeIdError ? 'Bitte geben Sie Ihre Personalnummer ein' : ''}>
              <Input id='employeeId' type='text' value={employeeId} onChange={(e) => onChange(e)} disabled={employeeIdReadOnly} />
            </Field>
          </Col>
          <Col xs={12} md={12} lg={3}>
              <Field label="Vorname" validationState={firstnameError ? 'error' : 'success'} validationMessage={firstnameError ? 'Bitte geben Sie Ihren Vornamen ein' : ''}>
                <Input id='firstname' type='text' value={firstname} onChange={(e) => onChange(e)} disabled={firstnameReadOnly} />
              </Field>
            </Col>
            <Col xs={12} md={12} lg={3}>
              <Field label="Nachname" validationState={surnameError ? 'error' : 'success'} validationMessage={surnameError ? 'Bitte geben Sie Ihren Nachnamen ein' : ''}>
                <Input id='surname' type='text' value={surname} onChange={(e) => onChange(e)} disabled={surnameReadOnly}  />
              </Field>
            </Col>
        </Row>
      </Container>
      
      <h2>Angaben Ihrer Kinder:</h2>
      <Container fluid>
          <Row style={{padding:0}}>
            <p style={{padding:0,marginTop:10}}>
          Bitte tragen Sie hier <span style={{fontSize:15}}><b>alle</b></span> Ihre Kinder ein und laden die dazugehörige Geburtsurkunde hoch. Es ist wichtig, dass Sie alle Ihre Kinder melden.
Es können nur Kinder berücksichtigt werden, für die Sie eine Geburtsurkunde vorweisen können.</p>
          </Row>
          { children && children.map(o => {
            return <ChildInfoContainer key={o.idx} item={o} deleteAction={(idx) => deleteItem(idx)} validateAction={() => updateValidation()}/>
          })}
          <Row style={{paddingTop:40}}>
            <Col xs={12} md={12} lg={6}>
              <Stack horizontal>
                
              { children.length < 15 && <StackItem style={{marginRight:30}}><Button onClick={() => addItem()}>Kind hinzufügen</Button></StackItem> }
              { !loading && <StackItem><PrimaryButton disabled={hasErrors} onClick={() => sendData() }>Absenden</PrimaryButton></StackItem> }
              { loading && <StackItem><Spinner size={SpinnerSize.large} /></StackItem>}
              </Stack>
            </Col>
          </Row>
      </Container>
    </div>
    } */}
    { dataSent && <div><SuccessAnimation
        text="Wir haben Ihre Daten erhalten. Vielen Dank!"
        color="#5cb85c"
        sliveRegion="live"
      /></div>}
  </div>;

}

export default App;
